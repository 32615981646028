@media screen and (max-width: 1024px) {
  body,
  html {
    font-size: .9em !important;
  }
}

.container {
  height: 100%;
  margin: 0;
  padding: 0;
}

.hidden {
    display: none !important;
}