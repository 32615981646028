html,
body,
#root {
  margin: 0;
  padding: 0;
}

.App {
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.popupbox.is-active {
  z-index: 2000;
}

.popupbox-content {
  padding: 0;
}

tfoot.tableFooterStyle div {
  margin-right: -20vw !important;
}
