table.plans td {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  table.plans {
    /* table-layout: fixed; */
  }

  table.plans td,
  table.plans th {
    padding: 4px 10px 4px 10px;
  }
}
