.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #ed1b24;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 90%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #ed1b24 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
