h1.or.or {
    overflow: hidden;
    text-align: center;
}

h1.or:before,
h1.or:after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 10%;
}

h1.or:before {
    right: 1em;
    margin-left: -50%;
}

h1.or:after {
    left: 1em;
    margin-right: -50%;
}

.nric-text,
.sme-text {
    font-weight: 700;
    color: #333333
}